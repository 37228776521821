.shop-personal-center {
}

.shop-personal-center .personalInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
}


.shop-personal-center .person-container-bg {
    background: #00a3ff;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 200px;
    border-radius: 0 0 30px 30px;
}

.shop-personal-center .personalInfoToolItem {
    color: #434343;
}

.shop-personal-center .avatar {
    flex: 0
}

.shop-personal-center .name {
    flex: 1;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 8px;
}

.shop-personal-center .personal-highlight {
    font-size: 24px;
    color: black;
}

.shop-personal-center .person-ps {
    font-size: 10px;
    color: var(--color);
    margin-top: 2px;
}

.shop-personal-center .personal-tool {
    width: 20px;
    height: 20px;
}

.shop-personal-center .personal-tools-icon  {
    width: 36px;
    height: 36px;
}

.shop-personal-center .personal-checked {
    font-size: 12px;
}

.shop-personal-center .personal-publisher {
    color: #FFFFFF;
    width: 70px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
}

.shop-personal-center .slogan {
    height: 60px;
    position: relative;
    bottom: -20px;
    z-index: 1;
    padding: 0 10px;
}

.shop-personal-center .shop-balance {
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.shop-personal-center .shop-balance .balance-block {
    justify-content: center;
    height: 70px;
    width: 185px;
    box-sizing: border-box;
    padding-left: 20px;
    border-radius: 10px;
}

.shop-personal-center .manage-tools {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
}

.shop-personal-center .bg-1 {
    background-image: url("../../assets/shop/person-bg-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
    border-radius: 10px;
}

.shop-personal-center .bg-2 {
    background-image: url("../../assets/shop/person-bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}

.shop-personal-center .bg-3 {
    background-image: url("../../assets/shop/person-bg-3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}

.shop-personal-center .icon-1 {
    background-image: url("../../assets/shop/person-icon-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}
.shop-personal-center .icon-2 {
    background-image: url("../../assets/shop/person-icon-2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}
.shop-personal-center .icon-3 {
    background-image: url("../../assets/shop/person-icon-3.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}
.shop-personal-center .icon-4 {
    background-image: url("../../assets/shop/person-icon-4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}

.shop-personal-center .prefix-1 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -10px;
}
.shop-personal-center .prefix-2 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -39px;
}
.shop-personal-center .prefix-3 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -65px;
}
.shop-personal-center .prefix-4 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -94px;
}
.shop-personal-center .prefix-5 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -123px;
}
.shop-personal-center .prefix-6 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -152px;
}
.shop-personal-center .prefix-7 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -178px;
}
.shop-personal-center .prefix-8 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -204px;
}

.shop-personal-center .prefix-9 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -233px;
}
.shop-personal-center .prefix-10 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -264px;
}

.shop-personal-center .prefix-11 {
    width: 50px;
    height: 25px;
    background-image: url("../../assets/shop/sprite.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10px -288px;
}