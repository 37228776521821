.agent-my-users .container {
    background-color: #fff;
    margin-top: 11px;
    padding: 11px
}

.agent-my-users .container .title {
    text-align: center
}

.agent-my-users .container .cont {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center
}

.agent-my-users .container .cont .t1 {
    width: 30%
}

.agent-my-users .container .cont .t2 {
    width: 20%
}

.agent-my-users .container .cont .t3 {
    width: 30%
}

.agent-my-users .container .cont .t4 {
    width: 20%
}

.agent-my-users .container .item {
    margin-top: 11px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #999;
    border-bottom: 1px solid #eaeaea
}

.agent-my-users .container .item .t1 {
    width: 30%
}

.agent-my-users .container .item .t2 {
    width: 20%
}

.agent-my-users .container .item .t3 {
    width: 30%
}

.agent-my-users .container .item .t4 {
    width: 20%
}

.agent-my-users .container .item .t4 .checkBtn {
    padding: 3px 11px;
    border-radius: 4px;
    background: #fc4b27;
    color: #fff
}

.agent-my-users .fee {
    color: #ff3737;
}