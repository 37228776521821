.statistics-container {
    background-color: var(--whole-page-background);
    height: 100%;
    padding: 12px;
}

.statistics-container .half-top {
    height: 85px;
    padding-left: var(--padding-left);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--fill-bg-color);
    border-radius: 4px;
}

.statistics-container .row-top {
    padding-left: var(--padding-left);
    padding-top: 30px;
    padding-bottom: 12px;
    color: var(--fill-bg-color);
    background-color: var(--adm-color-primary);
    border-radius: 4px;
}

.statistics-container .statistics-value {
    font-size: 16px;
}

.statistics-container .statistics-name {
    font-size: 12px;
}

.statistics-container .half-center {
    height: 85px;
    padding-left: var(--padding-left);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--fill-bg-color);
    border-radius: 4px;
}

.statistics-container .half-right-center {
    height: 85px;
    text-align: right;
    padding-right: var(--padding-left);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--fill-bg-color);
    border-radius: 4px;
}

.statistics-container .statistics-pink {
    background-color: #ff7875;
}

.statistics-container .statistics-blue {
    background-color: #69b1ff;
}

.statistics-container .statistics-yellow {
    background-color: #faad14;
}

.statistics-container .statistics-green {
    background-color: #95de64;
}

.statistics-container .statistics-box {
    background-color: #FFFFFF;
    padding: 12px;
}

.statistics-container .flex-end {
    justify-content: flex-end;
}
.statistics-container .bg {
    background-size: cover;
    background-repeat: no-repeat;
}
.statistics-container .bg-1 {
    background-image: url("../../assets/shop/statistics-bg-1.png");
}
.statistics-container .bg-2 {
    background-image: url("../../assets/shop/statistics-bg-2.png");
}
.statistics-container .bg-3 {
    background-image: url("../../assets/shop/statistics-bg-3.png");
}
.statistics-container .bg-4 {
    background-image: url("../../assets/shop/statistics-bg-4.png");
}
.statistics-container .bg-5 {
    background-image: url("../../assets/shop/statistics-bg-5.png");
}
.statistics-container .bg-6 {
    background-image: url("../../assets/shop/statistics-bg-6.png");
}
.statistics-container .bg-7 {
    background-image: url("../../assets/shop/statistics-bg-7.png");
}
.statistics-container .bg-8 {
    background-image: url("../../assets/shop/statistics-bg-8.png");
}
.statistics-container .bg-9 {
    background-image: url("../../assets/shop/statistics-bg-9.png");
}
.statistics-container .bg-10 {
    background-image: url("../../assets/shop/statistics-bg-10.png");
}
.statistics-container .bg-11 {
    background-image: url("../../assets/shop/statistics-bg-11.png");
}