.bet-status {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    text-align: center;
    background-color: #ebebeb;
}
.bet-status .item {
    flex: 1;
    background-color: white;
    padding: 10px;
    border: solid 1px #c7c7c7;
}

.bet-status .item span:nth-child(1) {
    font-size: 18px;
}

.bet-status .item span:nth-child(2) {
    font-size: 13px;
    color: gray;
}

.bet-info p span:nth-child(1) {
    padding: 5px;
    background-color: #c7c7c7;
    border-radius: 5px;
}
.bet-info .color-blue,
.bet-info .color-red {
    font-size: 15px;
    font-weight: 800;
    margin:0px 5px;
}

.bet-table-container {
    display: flex;
}

.bet-table-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border: solid 1px black;
    padding: 5px;
}


.color-blue {
    color: blue;
}

.color-red {
    color: red;
}