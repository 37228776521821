
.group-buy-item-detail .listBox {
    width: 95%;
    margin-top: 11px;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: 0 2px 12px 4px rgba(168, 167, 167, .12);
    margin-bottom: 113px;
    overflow: hidden
}

.group-buy-item-detail .listBox .listTop {
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0 17px;
    text-align: center
}

.group-buy-item-detail .listBox .listTop .listTopBox {
    display: flex;
    align-items: center;
    padding-top: 17px;
    flex-direction: column
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox {
    position: relative
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox .img1 {
    width: 79px;
    height: 79px;
    background: #e0e0e0;
    border: 2px solid #fac219;
    border-radius: 50%
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox .img2 {
    width: 45px;
    height: 45px;
    background: #e0e0e0;
    border: 2px solid #aeb6c2;
    border-radius: 50%
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox .img3 {
    width: 45px;
    height: 45px;
    background: #e0e0e0;
    border: 2px solid #d19d69;
    border-radius: 50%
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox .king {
    position: absolute;
    width: 52px;
    height: 48px;
    top: -29px;
    left: 91%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.group-buy-item-detail .listBox .listTop .listTopBox .avatarBox .king1 {
    position: absolute;
    width: 52px;
    height: 48px;
    top: -28px;
    left: 85%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.group-buy-item-detail .listBox .listTop .listTopBox .topName {
    margin-left: 6px
}

.group-buy-item-detail .listBox .list {
    margin-top: 11px
}

.group-buy-item-detail .listBox .list .center {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #16171a;
    border: 1px solid #eee;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center
}

.group-buy-item-detail .listBox .list .center .us-name {
    padding-left: 5px;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #eaeaea;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.group-buy-item-detail .listBox .list .center .us-name .uni-image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
}

.group-buy-item-detail .listBox .list .center .us-name span {
    width: 60%;
    margin-left: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left
}

.group-buy-item-detail .listBox .list .center .t {
    width: 18%;
    height: 100%;
    border-right: 1px solid #eaeaea;
    line-height: 50px
}

.group-buy-item-detail .listBox .list .center .d {
    width: 56px;
    height: 20px
}

.group-buy-item-detail .listBox .list .title {
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    color: #9599a6;
    border: 1px solid #eee;
    border-bottom: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center
}

.group-buy-item-detail .listBox .list .title .t {
    width: 18%;
    height: 100%;
    border-right: 1px solid #eaeaea
}

.group-buy-item-detail .listBox .list .title .t:first-child {
    width: 37%
}