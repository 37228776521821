@font-face {
  font-family: 'number-1';
  src: url(./assets/font/Aberforth_Tiles.ttf);
}

@-webkit-keyframes move {
  0% {
      background-position: 0 0;
  }

  100% {
      background-position: 40px 0;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

div {
  font-size: 15px;
  box-sizing: border-box;
  user-select: none;
}

a {
  text-decoration: none;
  color: #000;
}

input,textarea {
  outline: none;
}

.uni-image>img {
  width: 100%;
  height: 100%;
}

.adm-nav-bar {
  background-color: #00a3ff;
  color: white;
}

.adm-tabs-header {
  background: white;
}

.adm-nav-bar-back-arrow {
  font-size: 24px;
}

.adm-dropdown-item-highlight {
  color: white;
}

.aui-flexView {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.aui-navBar {
  height: 50px;
  line-height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ff4800;
}

.aui-scrollView {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* margin-bottom: 44px; */
}

.aui-center-title {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: #ffffff;
}

.aui-center {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  width: 50%;
  margin-left: 25%;
}


.grid-6-6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2-10 {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.grid-10-2 {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.grid-3-9 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-9-3 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-3-9_2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.grid-3-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.font-orange {
  color: #ff4800;
}


.btn-orange {
  color: #ffffff;
  background-color: #ff4800;
  text-align: center;
}
.btn-orange-1 {
  font-size: 14px;
  color: #ff4800;
  border: 1px solid #ff4800;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 3px 6px;
}

.btn-orange:hover {
  opacity: .75;
}

.table-2 {
  font-size: 12px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
}

.table-2 * {
  padding: 6px 0;
}

.table-2 th {
  border: 1px solid #cfcfcf;
  background-color: #f3f3f3;
  color: #5f5f5f;
}

.table-2 td {
  border: 1px solid #cfcfcf;
  overflow: auto;
}

.bg-gray {
  background: #f3f3f3 !important;
}

.check-orange {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.check-orange:checked {
  border-radius: 50%;
  background: url(./assets/images/checked.jpg) #ff4800 no-repeat center center;
  background-size: contain;
  color: #ffffff;
  text-align: center;
  font-weight: bolder;
}

.check-blue {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #cfcfcf;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.check-blue:checked {
  border-radius: 3px;
  background: url(./assets/images/checked-1.jpg) #008ae6 no-repeat center center;
  background-size: contain;
  color: #ffffff;
  text-align: center;
  font-weight: bolder;
}

.login-input {
  width: 100%;
  height: 50px;
  box-shadow: #7f7f7f7f 0 1px 6px;
  border-radius: 6px;
  padding: 12px 9px 12px 40px;
  margin: 9px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.btn-main {
  border-radius: 2em;
  padding: 18px 76px;
  font-weight: bold;
}

.font-money {
  color: #ff0000;
}


.topBox {
  background: #fff
}

.topBox .top {
  padding: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center
}

.topBox .top .dataBox {
  text-align: center;
  width: 45%;
  padding: 11px 0;
  border: 1px solid #ccc;
  border-radius: 6px
}

.topBox .search {
  padding: 11px
}

.topBox .box {
  margin-top: 11px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 15px;
  color: #9599a6;
  font-weight: 700
}

.topBox .box .left .title.active {
  color: #fc4b27;
  border-bottom: 3px solid #fc4b27
}

.topBox .box .right .title.active {
  color: #fc4b27;
  border-bottom: 3px solid #fc4b27
}