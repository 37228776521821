.bet-list {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.bet-list .adm-list-item-content-main {
    padding: 10px;
}

.bet-list .centerBox {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-top: 10px;
    align-items: center;
    font-size: 14px;
}

.bet-list .centerBox .left {
    width: 33.33%;
    text-align: left;
}

.bet-list .centerBox .left .t2 {
    margin-top: 7px;
    color: #999;
    font-size: 12px;
}

.bet-list .centerBox .center {
    width: 33.33%;
    justify-content: center;
}
.bet-list .centerBox .right {
    text-align: right;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bet-list .centerBox .right .t1 {
    color: #fc4b27;
}

.bet-list .content {
    margin-top: 0;
    flex: 1 1;
    overflow: auto;
}