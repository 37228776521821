.shop-action-list .content {
    margin-top: 0;
}
.shop-action-list .content .item {
    background-color: #fff;
    padding: 5px 12px;
    height: 85px;
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1px;
}
.shop-action-list .content .item .left {
    flex: 1;
}
.shop-action-list .content .item .d1 {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #4a4a4a;
    opacity: 1;
    margin-bottom: 9px;
}
.shop-action-list .content .item .d2 {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: red;
    opacity: 1;
}
.shop-action-list .content .item .left .d3 {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #188a88;
    opacity: 1;
}
.shop-action-list .content .item .right {
    flex: 1;
    text-align: right;
}
.shop-action-list .content .item .right .d2{
    font-size: 17px;
    color: #a2a2a2;
    opacity: 1;
}