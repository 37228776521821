.agent-management {
    height: 100%;
    background-color: var(--whole-page-background);
    /* padding: 12px; */
}

.agent-management-box {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 12px;
}

.agent-detail-box {
    border: 1px solid var(--border);
    border-radius: 12px;
    padding: 8px;
}

.agent-list-item-top-left-text {
    margin-left: 8px;
}

.agent-management .topBox .top {
    padding: 0;
}