.conversations {
    width: 100%;
    height: 100%;
    position: relative;
    color: #333333;
    display: flex;
    flex-direction: column;
}

.conversations .conversation-list {
    width: 100%;
    flex: 1 1;
    overflow: auto;
}

.conversations .conversation-list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-right: #dbd6d6 1px solid;
}

.conversations .conversation-list-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.conversations .conversation-list-content::-webkit-scrollbar {
    display: none;
}

.conversations .no-conversation {
    text-align: center;
    color: #666666;
}

.conversations .conversation {
    display: flex;
    padding: 10px 5px;
    cursor: pointer;
}

.conversations .unread-count {
    position: absolute;
    top: -10px;
    left: 30px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: white;
    background: #d02129;
}

.conversations .unread-count .unread {
    display: block;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
}

.conversations .conversation-message {
    flex: 1;
    padding-left: 5px;
    display: flex;
    width: 160px;
    flex-direction: column;
    justify-content: space-around;
}

.conversations .conversation-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
}

.conversations .conversation-name {
    font-size: 12px;
    font-weight: 500;
}

.conversations .conversation-time {
    color: #B9B9B9;
    display: flex;
    flex-direction: column;
}

.conversations .conversation-bottom {
    display: flex;
    color: #666666;
}

.conversations .conversation-content {
    display: flex;
    width: 160px;
    color: #b3b3b3;
}

.conversations .conversation-content .text {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    white-space: nowrap;
    word-break: break-all;
}

.conversations .conversation-bottom .unread-text {
    color: #d02129;
    width: 50px !important;
}

.conversations .conversation .avatar {
    width: 40px;
    height: 40px;
    position: relative;
}

.conversations .conversation .avatar img {
    width: 100%;
    border-radius: 10%;
}

.conversations .router-link-active {
    background: #eeeeee;
}

.conversations .action-box {
    width: 100px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #cccccc;
    position: fixed;
    z-index: 100;
    border-radius: 5px;
}

.conversations .action-box .action-item {
    padding-left: 15px;
    line-height: 30px;
    font-size: 13px;
    color: #262628;
    cursor: pointer;
}

.conversations .action-box .action-item:hover {
    background: #dddddd;
}

.conversations .chat {
    flex: 1;
    display: flex;
}