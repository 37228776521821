.match-table.item .container {
    padding: 8px 0;
    margin-top: -8px;
}

.match-table.item .container .c_red {
    color: #fc4b27;
}

.match-table.item {
    margin-top: 4px;
    background-color: #fff;
}
.match-table.item .top {
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.match-table.item .top .item_value {
    color: #999;
    font-size: 14px;
    display: inline-block;
    padding: 0 6px;
}
.match-table .item_class {
    height: 32px;
    border: 1px solid #79d055;
    border-radius: 5px;
    text-align: center;
    line-height: 32px;
    padding: 0 5px;
    margin: 0 10px;
}
.match-table.item .top .item_value1 {
    color: #77a6eb;
    border: 1px solid #77a6eb;
    font-size: 13px;
    display: inline-block;
    padding: 0 5px;
}
.match-table.item .top .item_value4 {
    color: #ff8b5f;
    border: 1px solid #ff8b5f;
}

.match-table.item .top .item_value5 {
    color: #ccc15e;
    border: 1px solid #ccc15e;
}

.match-table.item .noShowClass {
    width: 100vw;
    height: 89px;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    padding-top: 7px;
}

.match-table.item .noShowClass .noShowClass-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 38px;
}

.match-table.item .noShowClass .noShowClass-top .noShowClass-top-text {
    margin: 0 6px;
}

.match-table.item .noShowClass .noShowClass-bottom {
    height: 38px;
    line-height: 38px;
}